import {extendTheme} from '@chakra-ui/react';

const theme = extendTheme({
  fonts: {
    heading: 'Poppins, Helvetica, sans-serif',
    body: 'Roboto, Helvetica, sans-serif'
  },
  styles: {
    global: {
      '.quill': {
        [['.ql-toolbar', '.ql-container']]: {
          borderColor: 'inherit',
          bg: 'white'
        },
        '.ql-toolbar': {
          roundedTop: 'md'
        },
        '.ql-container': {
          roundedBottom: 'md',
          fontSize: 'inherit'
        },
        '.ql-editor': {
          minH: 300
        }
      }
    }
  },
  components: {
    Button: {
      baseStyle: {
        fontFamily: 'heading',
        fontWeight: 'medium'
      }
    }
  },
  textStyles: {
    smallCaps: {
      fontSize: 'sm',
      fontFamily: 'heading',
      fontWeight: 'semibold',
      textTransform: 'uppercase',
      letterSpacing: 'wider'
    }
  },
  colors: {
    gray: {
      50: '#f8f7ff',
      100: '#f0effb',
      200: '#e6e5f1',
      300: '#d5d5e0',
      400: '#b1b0bb',
      500: '#91909b',
      600: '#696872',
      700: '#55555f',
      800: '#373740',
      900: '#17171f'
    },
    pink: {
      50: '#fce4ec',
      100: '#f8bbd0',
      200: '#f48fb0',
      300: '#f06291',
      400: '#ec4079',
      500: '#e91e62',
      600: '#d81b5f',
      700: '#c2185a',
      800: '#ad1356',
      900: '#880d4e'
    },
    indigo: {
      50: '#e8eafc',
      100: '#c5c9f6',
      200: '#9ea7f0',
      300: '#7384ea',
      400: '#5167e6',
      500: '#264adf',
      600: '#2142d4',
      700: '#1137c7',
      800: '#002bbc',
      900: '#0015a5'
    },
    discord: {
      50: '#e9eafe',
      100: '#c8c9fa',
      200: '#a2a6f8',
      300: '#7983f5',
      400: '#5865f2',
      500: '#3446ed',
      600: '#303de1',
      700: '#2531d4',
      800: '#1824c9',
      900: '#0f03b1'
    }
  }
});

export default theme;

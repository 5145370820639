import fetch from 'isomorphic-fetch';
import gravatarUrl from 'gravatar-url';
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache
} from '@apollo/client';

const httpLink = new HttpLink({
  uri: `${process.env.GATSBY_API_URL}/graphql`,
  fetch
});

const authLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem(process.env.GATSBY_TOKEN_KEY);
  if (token) {
    operation.setContext({
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  return forward(operation);
});

// TODO: add afterware to log out if we get an authentication error

const cache = new InMemoryCache({
  typePolicies: {
    User: {
      fields: {
        avatar: {
          read: (existing, {readField}) =>
            gravatarUrl(readField('email'), {default: 'identicon'})
        }
      }
    }
  }
});

const client = new ApolloClient({
  cache,
  link: authLink.concat(httpLink)
});

export default client;

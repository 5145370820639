// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-guide-mdx": () => import("./../../../src/pages/guide.mdx" /* webpackChunkName: "component---src-pages-guide-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-mdx": () => import("./../../../src/pages/privacy.mdx" /* webpackChunkName: "component---src-pages-privacy-mdx" */),
  "component---src-pages-projects-[id]-js": () => import("./../../../src/pages/projects/[id].js" /* webpackChunkName: "component---src-pages-projects-[id]-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-terms-mdx": () => import("./../../../src/pages/terms.mdx" /* webpackChunkName: "component---src-pages-terms-mdx" */)
}

